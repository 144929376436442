html
    font-size: 62.5%
    font-family: $ff-base
    cursor: url('../img/cursor.png'), auto
    scroll-behavior: smooth

html:focus-within
    scroll-behavior: smooth

body
    font-size: 1.8rem
    overflow-x: hidden
    background-color: $white
    color: $dark

    &.is-fixed
        overflow: hidden

a
    &:focus
        outline: none

    &:focus-visible
        outline: 2px solid $gray-600

button
    &:focus
        outline: none

    &:focus-visible
        outline: 2px solid $gray-600

ul
    list-style: none

img
    height: auto
    max-width: 100%

.perex
    display: block
    width: 960px
    max-width: 100%
    margin: auto
    margin-bottom: 3rem
    position: relative
    z-index: 2

.relative
    position: relative
