.gallery__wrapper
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 36px
    @media screen and (max-width: $sm)
        gap: 16px
    @media screen and (max-width: $xs)
        grid-template-columns: 1fr

.gallery__item
    padding: 20px
    background-color: #fff
    color: $dark
    position: relative
    height: 550px
    border-radius: 20px
    display: flex
    align-items: flex-end
    padding-bottom: 24px
    overflow: hidden
    @media screen and (max-width: $lg)
        height: 350px
    @media screen and (max-width: $md)
        height: 300px
    @media screen and (max-width: $sm)
        padding: 12px
    &:hover
        .gallery__image
            img
                transform: scale(1.1)
        .gallery__title
            &::before
                width: 100px
.gallery__image
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 20px
        transition: all 250ms ease-in-out

.gallery__description
    display: flex
    align-items: flex-end
    justify-content: center
    z-index: 2
    max-width: 100%
    background: $primary
    border-radius: 30px
    padding: 18px 30px
    margin: 0 auto
    color: #fff

    *
        margin: 0
        line-height: 1
    // background: linear-gradient(0deg, rgba(255,255,255,.6) 50%, rgba(255,255,255,0) 100%)

// .gallery__title
//     text-align: center
//     position: relative
//     padding-bottom: 8px
//     &::before
//         position: absolute
//         content: ""
//         width: 0
//         height: 4px
//         background-color: $primary
//         top: 100%
//         left: 50%
//         transform: translateX(-50%)
//         transition: all 250ms ease-in-out
//         border-radius: 5px

.body-gallery-detail
    padding-top: 90px

.gallery-detail__wrapper
    display: flex
    gap: 8px
    flex-wrap: wrap

.gallery-detail__item
    display: block
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 10px
