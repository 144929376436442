.header
    position: fixed
    top: 0
    width: 100%
    max-width: 1920px
    padding: 2.5rem 0
    z-index: 99
    transition: padding 250ms ease-in-out
    background-color: $primary

    &.is-scroll
        padding: 1.5rem 0
        background-color: $primary !important

body.body-homepage .header
    background: transparent

.header__content
    display: flex
    justify-content: space-between
    align-items: center

.header__logo
    display: block
    line-height: 1

    img
        display: block
        line-height: 1
