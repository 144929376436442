.contact__wrapper
    display: grid
    gap: 20px
    grid-template-columns: 1fr 1fr
    align-items: center

    @media screen and (max-width: $sm)
        grid-template-columns: 1fr

.contact__map
    width: 100%
    padding: 5px

    iframe
        height: 380px
        width: 100%
        background: rgba(255, 255, 255, 0.1)
        border: 5px solid #FFFFFF
        box-shadow: 0px 4px 50px 20px rgba(247, 66, 31, 0.1), inset 0px 4px 30px 3px rgba(247, 66, 31, 0.1)
        backdrop-filter: blur(5px)
        border-radius: 30px

.contact__item
    position: relative
    padding-left: 50px
    margin-bottom: 20px

    img
        position: absolute
        top: 5px
        left: 0
