main

    & > section
        padding: 9rem 0

        @media screen and (max-width: $lg)
            padding: 6rem 0

        // & + section
        //     padding: 0 0 9rem 0

        //     @media screen and (max-width: $lg)
        //         padding: 0 0 6rem 0

.bg-primary
    background-color: $primary
