.transactions__wrapper
    position: relative
    background: rgba(255, 255, 255, 0.1)
    border: 5px solid #FFFFFF
    box-shadow: 0px 4px 100px 50px rgba(247, 66, 31, 0.2), inset 0px 4px 30px 3px rgba(247, 66, 31, 0.1)
    backdrop-filter: blur(5px)
    border-radius: 50px
    padding: 90px
    color: #fff
    z-index: 3

    a
        color: $white

    @media screen and (max-width: $md)
        padding: 70px

    @media screen and (max-width: $sm)
        padding: 40px 16px
        background-color: $sec
        border-radius: 20px
        background: $primary

    &::after
        content: ""
        position: absolute
        top: 30px
        left: 30px
        width: calc(100% - 60px)
        height: calc(100% - 60px)
        background-color: $primary
        border-radius: 50px
        z-index: -1

        @media screen and (max-width: $sm)
            display: none

    &::before
        content: ""
        position: absolute
        width: 50vw
        height: 50vw
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        background: #FFC155
        opacity: 0.4
        filter: blur(150px)

        @media screen and (max-width: $sm)
            display: none

.transactions
    position: relative
    display: flex
    flex-direction: column
    gap: 10px
    max-height: 50vh
    overflow-y: scroll
    z-index: 5

.transaction
    display: flex
    align-items: center
    padding: 10px 5px
    gap: 20px
    border-bottom: 3px solid

    @media screen and (max-width: $sm)
        flex-direction: column
        gap: 0

.transaction__value
    font-weight: 700
    font-size: 30px
    min-width: 5em

    &.--red
        color: $sec

    @media screen and (max-width: $sm)
        font-size: 26px
        width: 100%
        text-align: center

.transaction__donor
    font-weight: 700
    font-size: 20px
    width: 25%
    flex-shrink: 0

    @media screen and (max-width: $sm)
        width: 100%
        text-align: center

.transaction__msg
    @media screen and (max-width: $sm)
        width: 100%
        text-align: center

.trans__decor-1
    position: absolute
    bottom: 0
    left: 0
    z-index: 1
    translate: 0 20%
    width: 20vw
    height: auto

.trans__decor-qr
    position: absolute
    bottom: 100%
    right: 0
    z-index: 4
    translate: 0 20%
    width: 20vw
    height: auto
    pointer-events: none

.transaction__donor__account
    font-size: 14px
    font-weight: 400
