.hamburger
    position: relative
    display: none
    width: 3rem
    height: 3rem
    cursor: pointer
    z-index: 9999

    @media screen and (max-width: $lg)
        display: flex
        flex-direction: column
        gap: 5px

    input
        display: none

    &:before,
    &:after,
    span
        content: ''
        top: 0
        width: 100%
        height: .4rem
        background-color: $white
        border-radius: 3rem
        opacity: 1
        transition: transform 250ms
        transform-origin: center center

    span
        top: 50%
        transition: opacity 250ms

    &.is-active

        &:before
            transform: translateY(1rem) rotate(-45deg)

        &:after
            transform: translateY(-0.8rem) rotate(45deg)

        span
            opacity: 0
