h1,
.h1
    font-size: fz(40px, 100px)
    font-family: $ff-inter
    font-weight: 900

.h2,
h2
    font-size: fz(40px, 70px)
    font-family: $ff-inter
    font-weight: 900
    margin-bottom: .6em
    position: relative
    z-index: 2

.h3,
h3
    font-size: fz(22px, 30px)
    margin-bottom: 8px
    font-family: $ff-inter
    font-weight: 900

p, li, span
    line-height: 1.7

.about-modal p
    margin-bottom: 2em

a
    color: $primary
