*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote {
  margin: 0;
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
html {
  font-size: 62.5%;
  font-family: "antique-olive", sans-serif;
  cursor: url("../img/cursor.png"), auto;
  scroll-behavior: smooth;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  font-size: 1.8rem;
  overflow-x: hidden;
  background-color: #fff;
  color: #25262B;
}
body.is-fixed {
  overflow: hidden;
}

a:focus {
  outline: none;
}
a:focus-visible {
  outline: 2px solid #838383;
}

button:focus {
  outline: none;
}
button:focus-visible {
  outline: 2px solid #838383;
}

ul {
  list-style: none;
}

img {
  height: auto;
  max-width: 100%;
}

.perex {
  display: block;
  width: 960px;
  max-width: 100%;
  margin: auto;
  margin-bottom: 3rem;
  position: relative;
  z-index: 2;
}

.relative {
  position: relative;
}

h1,
.h1 {
  font-size: clamp(4rem, 5.5vw + 2.02rem, 10rem);
  font-family: "antique-olive", sans-serif;
  font-weight: 900;
}

.h2,
h2 {
  font-size: clamp(4rem, 2.75vw + 3.01rem, 7rem);
  font-family: "antique-olive", sans-serif;
  font-weight: 900;
  margin-bottom: 0.6em;
  position: relative;
  z-index: 2;
}

.h3,
h3 {
  font-size: clamp(2.2rem, 0.73vw + 1.94rem, 3rem);
  margin-bottom: 8px;
  font-family: "antique-olive", sans-serif;
  font-weight: 900;
}

p, li, span {
  line-height: 1.7;
}

.about-modal p {
  margin-bottom: 2em;
}

a {
  color: #F7421F;
}

.container {
  max-width: 145rem;
  padding: 0 3rem;
  margin: 0 auto;
}
@media screen and (max-width: 575px) {
  .container {
    padding: 0 1.5rem;
  }
}

.footer {
  padding: 3rem 0;
  background-color: #F7421F;
  color: #fff;
}

.footer__content {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .footer__content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer__content * {
    text-align: center;
  }
}
.footer__content p {
  margin-bottom: 0;
}
.footer__content p a {
  color: #fff;
}

.nav {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1199px) {
  .nav {
    flex-direction: column;
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: #25262B;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-15px);
    transition: all 250ms ease-in-out;
    overflow-y: auto;
  }
  .nav.is-open {
    padding: 1rem 0 15rem 0;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.nav__item {
  display: inline-block;
}
@media screen and (max-width: 1199px) {
  .nav__item {
    display: block;
    text-align: center;
  }
}
.nav__item.has-submenu {
  position: relative;
}
.nav__item.has-submenu a::after {
  display: none;
}
.nav__item.has-submenu ul {
  position: absolute;
  min-width: 30rem;
  background-color: #3A3A3A;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-25px);
  transition: all 250ms;
}
.nav__item.has-submenu ul li {
  display: block;
}
.nav__item.has-submenu ul li a {
  position: relative;
  display: block;
  color: #fff;
  padding: 1.2rem 3rem;
  text-decoration: none;
  overflow: hidden;
  font-size: clamp(1.4rem, 0.37vw + 1.27rem, 1.8rem);
  transition: color 250ms ease-in-out;
}
.nav__item.has-submenu ul li a:hover {
  color: #78C043;
}
@media screen and (min-width: 1200px) {
  .nav__item.has-submenu span:after {
    content: "";
    position: absolute;
    top: 50%;
    right: -5px;
    transform: translateY(-50%) scale(0.8);
    width: 2.4rem;
    height: 2.4rem;
    background-image: url("../img/icon/v-down.svg");
    transition: transform 250ms;
  }
}
@media screen and (max-width: 1199px) {
  .nav__item.has-submenu span {
    display: inline-block;
    position: absolute;
    top: 1.5rem;
    right: 0;
    width: 5.5rem;
    height: 4.5rem;
    cursor: pointer;
    background-color: #3A3A3A;
  }
  .nav__item.has-submenu span:before {
    content: "";
    position: absolute;
    top: 12px;
    right: 17px;
    transform: scale(0.9);
    width: 2.2rem;
    height: 2.2rem;
    background-image: url("../img/icon/v-down.svg");
    transition: transform 250ms ease-in-out;
  }
  .nav__item.has-submenu span:hover {
    border-color: #78C043;
  }
  .nav__item.has-submenu span:hover:before {
    transform: scale(0.9) rotateX(180deg);
  }
}
@media screen and (max-width: 575px) {
  .nav__item.has-submenu span {
    top: 1rem;
  }
}
@media screen and (min-width: 1200px) {
  .nav__item.has-submenu:hover ul, .nav__item.has-submenu.is-open ul {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }
  .nav__item.has-submenu:hover span:after, .nav__item.has-submenu.is-open span:after {
    transform: translateY(-50%) scale(0.8) rotate(180deg);
    background-image: url("../img/icon/v-down.svg");
  }
}
@media screen and (max-width: 1199px) {
  .nav__item.has-submenu.is-open ul {
    position: relative;
    background-color: #3A3A3A;
    padding: 2rem 0;
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }
  .nav__item.has-submenu.is-open span {
    border-color: #25262B;
  }
  .nav__item.has-submenu.is-open span:before {
    transform: scale(0.9) rotateX(180deg);
  }
}

.nav__link {
  position: relative;
  display: block;
  color: #fff;
  padding: 2rem 3rem;
  text-decoration: none;
  overflow: hidden;
  font-size: clamp(1.6rem, 0.55vw + 1.4rem, 2.2rem);
}
.nav__link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 10px);
  width: calc(100% - 6rem);
  height: 2px;
  background-color: #78C043;
  transition: transform 250ms ease-in-out;
}
@media screen and (max-width: 1199px) {
  .nav__link::after {
    width: 30%;
    transform: translate(-50%, 15px);
  }
}
.nav__link:hover::after {
  transform: translate(-50%, -5px);
}
.nav__link.is-active {
  color: #78C043;
}
@media screen and (max-width: 1399px) {
  .nav__link {
    padding: 3rem;
  }
}
@media screen and (max-width: 1199px) {
  .nav__link {
    position: relative;
    padding: 2rem;
    min-width: 30rem;
  }
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 1920px;
  padding: 2.5rem 0;
  z-index: 99;
  transition: padding 250ms ease-in-out;
  background-color: #F7421F;
}
.header.is-scroll {
  padding: 1.5rem 0;
  background-color: #F7421F !important;
}

body.body-homepage .header {
  background: transparent;
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  display: block;
  line-height: 1;
}
.header__logo img {
  display: block;
  line-height: 1;
}

main > section {
  padding: 9rem 0;
}
@media screen and (max-width: 1199px) {
  main > section {
    padding: 6rem 0;
  }
}

.bg-primary {
  background-color: #F7421F;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text h1,
.text h2,
.text h3,
.text h4,
.text h5,
.text h6 {
  color: #fff;
  font-weight: 700;
}

a.fill__link {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.btn {
  position: relative;
  display: block;
  letter-spacing: 1.5px;
  width: fit-content;
  height: fit-content;
  padding: 1.6rem 3rem;
  color: #fff;
  background-color: #F7421F;
  text-align: center;
  border: 3px solid #F7421F;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  transition: all 250ms;
  font-size: clamp(1.6rem, 0.18vw + 1.53rem, 1.8rem);
  border-radius: 8px;
}
.btn.--reverse {
  background-color: #fff;
  color: #F7421F;
}
.btn:hover {
  color: #25262B;
  background-color: #fff;
  border-color: #F7421F;
}

.btn__group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;
}

.hamburger {
  position: relative;
  display: none;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  z-index: 9999;
}
@media screen and (max-width: 1199px) {
  .hamburger {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
.hamburger input {
  display: none;
}
.hamburger:before, .hamburger:after,
.hamburger span {
  content: "";
  top: 0;
  width: 100%;
  height: 0.4rem;
  background-color: #fff;
  border-radius: 3rem;
  opacity: 1;
  transition: transform 250ms;
  transform-origin: center center;
}
.hamburger span {
  top: 50%;
  transition: opacity 250ms;
}
.hamburger.is-active:before {
  transform: translateY(1rem) rotate(-45deg);
}
.hamburger.is-active:after {
  transform: translateY(-0.8rem) rotate(45deg);
}
.hamburger.is-active span {
  opacity: 0;
}

section.banner__section {
  position: relative;
  background-color: #F7421F;
  padding: 0;
  color: #fff;
}
section.banner__section::before {
  content: "";
  position: absolute;
  width: 35vw;
  height: 35vw;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #FFC155;
  opacity: 0.4;
  filter: blur(150px);
}

.banner {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .banner {
    flex-direction: column-reverse;
  }
}

.banner__content {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  width: 50%;
  min-height: 100vh;
  z-index: 3;
}
@media screen and (max-width: 1199px) {
  .banner__content {
    padding: 250px 0 90px;
    width: 100%;
    min-height: 0vh;
    gap: 20px;
  }
}

.banner__image {
  position: absolute;
  top: 50%;
  right: 0;
  width: 55%;
  height: auto;
  translate: 0 -36%;
}
.banner__image::after {
  content: "";
  position: absolute;
  bottom: 15%;
  right: 0;
  width: 30vw;
  height: 30vw;
  background: #000000;
  opacity: 0.7;
  filter: blur(150px);
}
@media screen and (max-width: 767px) {
  .banner__image {
    width: 100%;
    translate: 0;
    top: 80px;
  }
}
.banner__image img {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  margin-right: 0;
  margin-left: auto;
  z-index: 5;
}
@media screen and (max-width: 767px) {
  .banner__image img {
    height: 250px;
    width: auto;
  }
}

.countdown {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}
@media screen and (max-width: 991px) {
  .countdown {
    gap: 8px;
  }
}
.countdown .bloc-time {
  position: relative;
  text-align: center;
  display: flex;
  gap: 1px;
  border-radius: 10px;
  line-height: 100px;
}
@media screen and (max-width: 991px) {
  .countdown .bloc-time {
    line-height: 50px;
  }
}
.countdown .count-title {
  position: absolute;
  top: 0;
  left: 50%;
  height: auto;
  text-transform: uppercase;
  transform: translate(-50%, -110%);
  text-transform: uppercase;
  height: 18px;
  line-height: 18px;
  font-weight: 700;
  z-index: 40;
}
.countdown .figure {
  position: relative;
  display: flex;
  height: 100px;
  width: 60px;
  background-color: #fff;
  overflow: hidden;
  border-radius: 10px;
}
@media screen and (max-width: 991px) {
  .countdown .figure {
    width: 34px;
    height: 50px;
  }
}
.countdown .figure * {
  color: #F7421F !important;
}
.countdown .figure:last-child {
  margin-right: 0;
}
.countdown .figure > span {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 60px;
  color: #F7421F;
}
@media screen and (max-width: 991px) {
  .countdown .figure > span {
    font-size: 30px;
  }
}
.countdown .figure .top:after, .countdown .figure .bottom-back:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.countdown .figure .top {
  z-index: 3;
  background-color: #f2f2f2;
  transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
  transform: perspective(200px);
}
.countdown .figure .bottom {
  z-index: 1;
}
.countdown .figure .bottom:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.02);
}
.countdown .figure .bottom-back {
  z-index: 2;
  top: 0;
  height: 50%;
  overflow: hidden;
  background-color: #fff;
}
.countdown .figure .bottom-back span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.countdown .figure .top, .countdown .figure .top-back {
  height: 50%;
  overflow: hidden;
  backface-visibility: hidden;
}
.countdown .figure .top-back {
  z-index: 4;
  bottom: 0;
  background-color: #f2f2f2;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  transform: perspective(200px) rotateX(180deg);
}
.countdown .figure .top-back span {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  margin: auto;
}

.transactions__wrapper {
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  border: 5px solid #FFFFFF;
  box-shadow: 0px 4px 100px 50px rgba(247, 66, 31, 0.2), inset 0px 4px 30px 3px rgba(247, 66, 31, 0.1);
  backdrop-filter: blur(5px);
  border-radius: 50px;
  padding: 90px;
  color: #fff;
  z-index: 3;
}
.transactions__wrapper a {
  color: #fff;
}
@media screen and (max-width: 991px) {
  .transactions__wrapper {
    padding: 70px;
  }
}
@media screen and (max-width: 767px) {
  .transactions__wrapper {
    padding: 40px 16px;
    background-color: #FFC155;
    border-radius: 20px;
    background: #F7421F;
  }
}
.transactions__wrapper::after {
  content: "";
  position: absolute;
  top: 30px;
  left: 30px;
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  background-color: #F7421F;
  border-radius: 50px;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .transactions__wrapper::after {
    display: none;
  }
}
.transactions__wrapper::before {
  content: "";
  position: absolute;
  width: 50vw;
  height: 50vw;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #FFC155;
  opacity: 0.4;
  filter: blur(150px);
}
@media screen and (max-width: 767px) {
  .transactions__wrapper::before {
    display: none;
  }
}

.transactions {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 50vh;
  overflow-y: scroll;
  z-index: 5;
}

.transaction {
  display: flex;
  align-items: center;
  padding: 10px 5px;
  gap: 20px;
  border-bottom: 3px solid;
}
@media screen and (max-width: 767px) {
  .transaction {
    flex-direction: column;
    gap: 0;
  }
}

.transaction__value {
  font-weight: 700;
  font-size: 30px;
  min-width: 5em;
}
.transaction__value.--red {
  color: #FFC155;
}
@media screen and (max-width: 767px) {
  .transaction__value {
    font-size: 26px;
    width: 100%;
    text-align: center;
  }
}

.transaction__donor {
  font-weight: 700;
  font-size: 20px;
  width: 25%;
  flex-shrink: 0;
}
@media screen and (max-width: 767px) {
  .transaction__donor {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .transaction__msg {
    width: 100%;
    text-align: center;
  }
}

.trans__decor-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  translate: 0 20%;
  width: 20vw;
  height: auto;
}

.trans__decor-qr {
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 4;
  translate: 0 20%;
  width: 20vw;
  height: auto;
  pointer-events: none;
}

.transaction__donor__account {
  font-size: 14px;
  font-weight: 400;
}

.about__box {
  position: relative;
  background: rgba(255, 255, 255, 0.8);
  border: 5px solid #FFFFFF;
  box-shadow: 0px 4px 30px 20px rgba(255, 193, 85, 0.15), inset 0px 4px 30px 3px rgba(255, 193, 85, 0.1);
  backdrop-filter: blur(5px);
  border-radius: 50px;
  padding: 100px;
  z-index: 3;
}
@media screen and (max-width: 991px) {
  .about__box {
    padding: 70px;
  }
}
@media screen and (max-width: 767px) {
  .about__box {
    padding: 40px 16px;
    background-color: #FFC155;
    border-radius: 20px;
  }
}
.about__box::after {
  content: "";
  position: absolute;
  top: 30px;
  left: 30px;
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  background-color: #FFC155;
  border-radius: 38px;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .about__box::after {
    display: none;
  }
}

ul.about__list {
  display: grid;
  width: 70%;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}
@media screen and (max-width: 991px) {
  ul.about__list {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 767px) {
  ul.about__list {
    width: 100%;
  }
}
ul.about__list li {
  position: relative;
  padding-left: 38px;
}
@media screen and (max-width: 767px) {
  ul.about__list li {
    padding-left: 20px;
  }
}
ul.about__list li::before {
  content: "";
  position: absolute;
  background-image: url(../img/icon/check.svg);
  background-size: contain;
  width: 26px;
  height: 26px;
  top: 5px;
  left: 0;
}
@media screen and (max-width: 767px) {
  ul.about__list li::before {
    width: 16px;
    height: 16px;
    top: 10px;
    left: 0;
  }
}

.about__image {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(20%, 20%);
}
@media screen and (max-width: 991px) {
  .about__image {
    width: 36%;
  }
}
@media screen and (max-width: 767px) {
  .about__image {
    top: 0;
    transform: translate(0%, -90%);
    bottom: auto;
  }
}

.about__section {
  position: relative;
}

.about__decor-1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  translate: 0 -40%;
  width: 20vw;
  height: auto;
}

.sponsors__wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 40px;
}
@media screen and (max-width: 767px) {
  .sponsors__wrapper {
    grid-template-columns: 1fr;
  }
}

.sponsors {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  z-index: 10;
  margin-bottom: 40px;
}
@media screen and (max-width: 991px) {
  .sponsors {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 575px) {
  .sponsors {
    grid-template-columns: 1fr 1fr;
  }
}

.sponsor {
  padding: 20px;
  background-color: #fff;
  color: #25262B;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0px 4px 100px 10px rgba(255, 193, 85, 0.24);
  transition: transform 250ms ease-in-out;
}
.sponsor img {
  display: block;
  width: 100%;
  height: auto;
  filter: grayscale(1);
  transition: all 250ms ease-in-out;
}
.sponsor h3 {
  margin-bottom: 0;
}
.sponsor:hover {
  transform: translateY(-15px);
}
.sponsor:hover img {
  filter: grayscale(0);
}

.contestants {
  position: relative;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  z-index: 2;
}
@media screen and (max-width: 991px) {
  .contestants {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 767px) {
  .contestants {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 575px) {
  .contestants {
    grid-template-columns: 1fr;
  }
}

.contestant {
  padding: 16px;
  background: rgba(255, 193, 85, 0.15);
  border: 5px solid #FFFFFF;
  box-shadow: 0px 4px 100px 10px rgba(255, 193, 85, 0.24);
  backdrop-filter: blur(50px);
  border-radius: 30px;
}

.contestant__video {
  display: block;
  width: 100%;
  aspect-ratio: 16/9;
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 20px;
}
.contestant__video::after {
  content: "";
  position: absolute;
  bottom: 30px;
  right: 28px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 20px;
  border-color: transparent transparent transparent #ffffff;
  z-index: 5;
}
.contestant__video::before {
  content: "";
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 60px;
  height: 60px;
  background: #F7421F;
  border-radius: 50%;
  z-index: 4;
  transition: scale 250ms ease-in-out;
}
.contestant__video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
  transform-origin: center center;
  transition: scale 500ms ease-in-out;
}
.contestant__video:hover img, .contestant__video:hover::before {
  scale: 1.2;
}

.contest__decor-1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  translate: 0 -40%;
  width: 40vw;
  height: auto;
}

.contest__decor-3 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  translate: 0 40%;
  width: 40vw;
  height: auto;
}

.gallery__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 36px;
}
@media screen and (max-width: 767px) {
  .gallery__wrapper {
    gap: 16px;
  }
}
@media screen and (max-width: 575px) {
  .gallery__wrapper {
    grid-template-columns: 1fr;
  }
}

.gallery__item {
  padding: 20px;
  background-color: #fff;
  color: #25262B;
  position: relative;
  height: 550px;
  border-radius: 20px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 24px;
  overflow: hidden;
}
@media screen and (max-width: 1199px) {
  .gallery__item {
    height: 350px;
  }
}
@media screen and (max-width: 991px) {
  .gallery__item {
    height: 300px;
  }
}
@media screen and (max-width: 767px) {
  .gallery__item {
    padding: 12px;
  }
}
.gallery__item:hover .gallery__image img {
  transform: scale(1.1);
}
.gallery__item:hover .gallery__title::before {
  width: 100px;
}

.gallery__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.gallery__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  transition: all 250ms ease-in-out;
}

.gallery__description {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 2;
  max-width: 100%;
  background: #F7421F;
  border-radius: 30px;
  padding: 18px 30px;
  margin: 0 auto;
  color: #fff;
}
.gallery__description * {
  margin: 0;
  line-height: 1;
}

.body-gallery-detail {
  padding-top: 90px;
}

.gallery-detail__wrapper {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.gallery-detail__item {
  display: block;
}
.gallery-detail__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.contact__wrapper {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .contact__wrapper {
    grid-template-columns: 1fr;
  }
}

.contact__map {
  width: 100%;
  padding: 5px;
}
.contact__map iframe {
  height: 380px;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 5px solid #FFFFFF;
  box-shadow: 0px 4px 50px 20px rgba(247, 66, 31, 0.1), inset 0px 4px 30px 3px rgba(247, 66, 31, 0.1);
  backdrop-filter: blur(5px);
  border-radius: 30px;
}

.contact__item {
  position: relative;
  padding-left: 50px;
  margin-bottom: 20px;
}
.contact__item img {
  position: absolute;
  top: 5px;
  left: 0;
}