.footer
    padding: 3rem 0
    background-color: $primary
    color: #fff

.footer__content
    display: flex
    justify-content: space-between

    @media screen and (max-width: $sm)
        flex-direction: column
        align-items: center
        justify-content: center

        *
            text-align: center

    p
        margin-bottom: 0
        a
            color: $white
