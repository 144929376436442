a.fill__link
    position: absolute
    display: block
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 10
.btn
    position: relative
    display: block
    letter-spacing: 1.5px
    width: fit-content
    height: fit-content
    padding: 1.6rem 3rem
    color: #fff
    background-color: $primary
    text-align: center
    border: 3px solid $primary
    outline: none
    cursor: pointer
    text-decoration: none
    transition: all 250ms
    font-size: fz(16px, 18px)
    border-radius: 8px

    &.--reverse
        background-color: $white
        color: $primary

    &:hover
        color: $dark
        background-color: $white
        border-color: $primary

.btn__group
    display: flex
    flex-wrap: wrap
    gap: 20px
    margin-top: 40px
