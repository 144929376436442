$cd-font-size: 60px
$cd-height: 100px
$cd-width: 60px

$cd-font-size-sm: 30px
$cd-width-sm: 34px
$cd-height-sm: 50px

// Countdown
.countdown
    margin: 0 auto
    display: flex
    flex-wrap: wrap
    // align-items: center
    justify-content: flex-start
    gap: 10px

    @media screen and (max-width: $md)
        gap: 8px

    .bloc-time
        position: relative
        text-align: center
        display: flex
        gap: 1px
        border-radius: 10px
        line-height: $cd-height

        @media screen and (max-width: $md)
            line-height: $cd-height-sm

    .count-title
        position: absolute
        top: 0
        left: 50%
        height: auto
        text-transform: uppercase
        transform: translate(-50%,-110%)
        text-transform: uppercase
        height: 18px
        line-height: 18px
        font-weight: 700
        z-index: 40

    .figure
        position: relative
        display: flex
        height: $cd-height
        width: $cd-width
        background-color: #fff
        overflow: hidden
        border-radius: 10px

        @media screen and (max-width: $md)
            width: $cd-width-sm
            height: $cd-height-sm

        *
            color: $primary !important

        &:last-child
            margin-right: 0

        >span
            position: absolute
            left: 0
            right: 0
            margin: auto
            font-size: $cd-font-size
            color: $primary

            @media screen and (max-width: $md)
                font-size: $cd-font-size-sm

        .top, .bottom-back
            &:after
                content: ""
                position: absolute
                z-index: -1
                left: 0
                bottom: 0
                width: 100%
                height: 100%
                border-bottom: 1px solid rgba(0, 0, 0, .3)

        .top
            z-index: 3
            background-color: darken(#fff, 5%)
            transform-origin: 50% 100%
            -webkit-transform-origin: 50% 100%
            transform: perspective(200px)

        .bottom
            z-index: 1

            &:before
                content: ""
                position: absolute
                display: block
                top: 0
                left: 0
                width: 100%
                height: 50%
                background-color: rgba(0, 0, 0, .02)

        .bottom-back
            z-index: 2
            top: 0
            height: 50%
            overflow: hidden
            background-color: #fff

            span
                position: absolute
                top: 0
                left: 0
                right: 0
                margin: auto

        .top, .top-back
            height: 50%
            overflow: hidden
            backface-visibility: hidden

        .top-back
            z-index: 4
            bottom: 0
            background-color: darken(#fff, 5%)
            -webkit-transform-origin: 50% 0
            transform-origin: 50% 0
            transform: perspective(200px) rotateX(180deg)

            span
                position: absolute
                top: -100%
                left: 0
                right: 0
                margin: auto
