.sponsors__wrapper
    display: grid
    grid-template-columns: 1fr 2fr
    gap: 40px

    @media screen and (max-width: $sm)
        grid-template-columns: 1fr

.sponsors
    position: relative
    display: grid
    grid-template-columns: 1fr 1fr 1fr 1fr
    gap: 20px
    z-index: 10
    margin-bottom: 40px

    @media screen and (max-width: $md)
        grid-template-columns: 1fr 1fr 1fr

    // @media screen and (max-width: $xs)
    //     grid-template-columns: 1fr 1fr

    @media screen and (max-width: $xs)
        grid-template-columns: 1fr 1fr

.sponsor
    padding: 20px
    background-color: #fff
    color: $dark
    display: flex
    align-items: center
    text-align: center
    border-radius: 20px
    box-shadow: 0px 4px 100px 10px rgba(255, 193, 85, 0.24)
    transition: transform 250ms ease-in-out

    img
        display: block
        width: 100%
        height: auto
        filter: grayscale(1)
        transition: all 250ms ease-in-out

    h3
        margin-bottom: 0

    &:hover
        transform: translateY(-15px)

        img
            filter: grayscale(0)
