.nav
    display: flex
    align-items: center

    @media screen and (max-width: $lg)
        flex-direction: column
        position: absolute
        top: calc(100% - 1px)
        left: 0
        height: 100vh
        width: 100%
        background-color: $dark
        opacity: 0
        visibility: hidden
        transform: translateY(-15px)
        transition: all 250ms ease-in-out
        overflow-y: auto

        &.is-open
            padding: 1rem 0 15rem 0
            opacity: 1
            visibility: visible
            transform: translateY(0)

.nav__item
    display: inline-block

    @media screen and (max-width: $lg)
        display: block
        text-align: center

    &.has-submenu
        position: relative

        a
            &::after
                display: none

        ul
            position: absolute
            min-width: 30rem
            background-color: $gray-800
            visibility: hidden
            opacity: 0
            transform: translateY(-25px)
            transition: all 250ms

            li
                display: block

                a
                    position: relative
                    display: block
                    color: $white
                    padding: 1.2rem 3rem
                    text-decoration: none
                    overflow: hidden
                    font-size: fz(14px, 18px)
                    transition: color 250ms ease-in-out

                    &:hover
                        color: $green-400

        span
            @media screen and (min-width: $lg-m)
                &:after
                    content: ''
                    position: absolute
                    top: 50%
                    right: -5px
                    transform: translateY(-50%) scale(.8)
                    width: 2.4rem
                    height: 2.4rem
                    background-image: url('../img/icon/v-down.svg')
                    transition: transform 250ms

            @media screen and (max-width: $lg)
                display: inline-block
                position: absolute
                top: 1.5rem
                right: 0
                width: 5.5rem
                height: 4.5rem
                cursor: pointer
                background-color: $gray-800

                &:before
                    content: ''
                    position: absolute
                    top: 12px
                    right: 17px
                    transform: scale(.9)
                    width: 2.2rem
                    height: 2.2rem
                    background-image: url('../img/icon/v-down.svg')
                    transition: transform 250ms ease-in-out

                &:hover
                    border-color: $green-400

                    &:before
                        transform: scale(.9) rotateX(180deg)

            @media screen and (max-width: $xs)
                top: 1rem

        &:hover,
        &.is-open
            @media screen and (min-width: $lg-m)
                ul
                    transform: translateY(0)
                    visibility: visible
                    opacity: 1

                span
                    &:after
                        transform: translateY(-50%) scale(.8) rotate(180deg)
                        background-image: url('../img/icon/v-down.svg')

        &.is-open
            @media screen and (max-width: $lg)
                ul
                    position: relative
                    background-color: $gray-800
                    padding: 2rem 0
                    transform: translateY(0)
                    visibility: visible
                    opacity: 1

                span
                    border-color: $dark

                    &:before
                        transform: scale(.9) rotateX(180deg)

.nav__link
    position: relative
    display: block
    color: $white
    padding: 2rem 3rem
    text-decoration: none
    overflow: hidden
    font-size: fz(16px, 22px)

    &::after
        content: ''
        position: absolute
        bottom: 0
        left: 50%
        transform: translate(-50%, 10px)
        width: calc(100% - 6rem)
        height: 2px
        background-color: $green-400
        transition: transform 250ms ease-in-out

        @media screen and (max-width: $lg)
            width: 30%
            transform: translate(-50%, 15px)

    &:hover
        &::after
            transform: translate(-50%, -5px)

    &.is-active
        color: $green-400

    @media screen and (max-width: $xl)
        padding: 3rem

    @media screen and (max-width: $lg)
        position: relative
        padding: 2rem
        min-width: 30rem
