section.banner__section
    // min-height: 100vh
    position: relative
    background-color: $primary
    padding: 0
    color: #fff

    &::before
        content: ""
        position: absolute
        width: 35vw
        height: 35vw
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        background: #FFC155
        opacity: 0.4
        filter: blur(150px)

.banner
    display: flex
    align-items: center

    @media screen and (max-width: $sm)
        flex-direction: column-reverse

.banner__content
    position: relative
    display: flex
    justify-content: center
    flex-direction: column
    gap: 40px
    width: 50%
    min-height: 100vh
    z-index: 3

    @media screen and (max-width: $lg)
        padding: 250px 0 90px
        width: 100%
        min-height: 0vh
        gap: 20px

.banner__image
    position: absolute
    top: 50%
    right: 0
    width: 55%
    height: auto
    translate: 0 -36%

    &::after
        content: ""
        position: absolute
        bottom: 15%
        right: 0
        width: 30vw
        height: 30vw
        background: #000000
        opacity: 0.7
        filter: blur(150px)

    @media screen and (max-width: $sm)
        width: 100%
        translate: 0
        top: 80px

    img
        position: relative
        display: block
        width: 100%
        height: auto
        margin-right: 0
        margin-left: auto
        z-index: 5

        @media screen and (max-width: $sm)
            height: 250px
            width: auto
