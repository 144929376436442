.contestants
    position: relative
    display: grid
    gap: 20px
    grid-template-columns: 1fr 1fr 1fr 1fr
    z-index: 2

    @media screen and (max-width: $md)
        grid-template-columns: 1fr 1fr 1fr

    @media screen and (max-width: $sm)
        grid-template-columns: 1fr 1fr

    @media screen and (max-width: $xs)
        grid-template-columns: 1fr

.contestant
    padding: 16px
    background: rgba(255, 193, 85, 0.15)
    border: 5px solid #FFFFFF
    box-shadow: 0px 4px 100px 10px rgba(255, 193, 85, 0.24)
    backdrop-filter: blur(50px)
    border-radius: 30px


.contestant__video
    display: block
    width: 100%
    aspect-ratio: 16/9
    position: relative
    margin-bottom: 20px
    overflow: hidden
    border-radius: 20px

    // Arrow
    &::after
        content: ""
        position: absolute
        bottom: 30px
        right: 28px
        width: 0
        height: 0
        border-style: solid
        border-width: 10px 0 10px 20px
        border-color: transparent transparent transparent #ffffff
        z-index: 5

    // Circle
    &::before
        content: ""
        position: absolute
        bottom: 10px
        right: 10px
        width: 60px
        height: 60px
        background: $primary
        border-radius: 50%
        z-index: 4
        transition: scale 250ms ease-in-out

    img
        width: 100%
        height: 100%
        object-fit: cover
        z-index: 2
        transform-origin: center center
        transition: scale 500ms ease-in-out

    &:hover
        img, &::before
            scale: 1.2


.contest__decor-1
    position: absolute
    top: 0
    left: 0
    z-index: 1
    translate: 0 -40%
    width: 40vw
    height: auto

.contest__decor-3
    position: absolute
    bottom: 0
    right: 0
    z-index: 1
    translate: 0 40%
    width: 40vw
    height: auto
