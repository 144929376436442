$about-box-br: 50px

.about__box
    position: relative
    background: rgba(255, 255, 255, 0.8)
    border: 5px solid #FFFFFF
    box-shadow: 0px 4px 30px 20px rgba(255, 193, 85, 0.15), inset 0px 4px 30px 3px rgba(255, 193, 85, 0.1)
    backdrop-filter: blur(5px)
    border-radius: $about-box-br
    padding: 100px
    z-index: 3

    @media screen and (max-width: $md)
        padding: 70px

    @media screen and (max-width: $sm)
        padding: 40px 16px
        background-color: $sec
        border-radius: 20px

    &::after
        content: ""
        position: absolute
        top: 30px
        left: 30px
        width: calc(100% - 60px)
        height: calc(100% - 60px)
        background-color: $sec
        border-radius: calc($about-box-br - 12px)
        z-index: -1

        @media screen and (max-width: $sm)
            display: none

ul.about__list
    display: grid
    width: 70%
    grid-template-columns: 1fr 1fr
    gap: 40px

    @media screen and (max-width: $md)
        grid-template-columns: 1fr

    @media screen and (max-width: $sm)
        width: 100%


    li
        position: relative
        padding-left: 38px

        @media screen and (max-width: $sm)
            padding-left: 20px

        &::before
            content: ""
            position: absolute
            background-image: url(../img/icon/check.svg)
            background-size: contain
            width: 26px
            height: 26px
            top: 5px
            left: 0

            @media screen and (max-width: $sm)
                width: 16px
                height: 16px
                top: 10px
                left: 0

.about__image
    position: absolute
    bottom: 0
    right: 0
    transform: translate(20%, 20%)

    @media screen and (max-width: $md)
        width: 36%

    @media screen and (max-width: $sm)
        top: 0
        transform: translate(0%, -90%)
        bottom: auto

.about__section
    position: relative

.about__decor-1
    position: absolute
    top: 0
    left: 0
    z-index: 1
    translate: 0 -40%
    width: 20vw
    height: auto
