.text-center
    text-align: center

.text-right
    text-align: right

.text
    h1,
    h2,
    h3,
    h4,
    h5,
    h6
        color: $white
        font-weight: 700

//     h1

//     h2

//     h3

//     h4

//     h6

//     ul,
//     ol
//         li
//             a

    p
        color: $gray-600
        line-height: 1.3em
        font-size: fz(16px, 20px)
//         a

//     a

//     img

//     table
//         thead
//             tr
//                 th

//         tbody
//             tr
//                 td
